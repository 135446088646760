import './App.css';
import React, { useState, Fragment } from 'react';
import ReactCardFlip from 'react-card-flip';
import { Transition, Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import ReactCurvedText from 'react-curved-text';
import logo from './images/logo.png';
import papaya from './images/papaya.jpg';
import teya from './images/teya.jpg';
import liam from './images/liam.JPG';

function App() {
  const [openBottom, setOpenBottom] = useState(false);
  return (
    <div className="App">
      <div className="h-screen h-full max-h-full bg-[url(images/background.jpg)] bg-cover bg-center">
        {/* Good Dogs */}
        <div className="absolute top-0 left-0 mt-4 ml-4">
          <img src={logo} alt="Logo" className="h-16 w-16 rounded-full"></img>
        </div>
        {/* Team Page */}
        <button
          className="fixed bottom-0 left-0 mb-4 ml-4 px-4 py-2 rounded-lg bg-blue-500 text-white shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          onClick={() => setOpenBottom(!openBottom)}
        >
          Team
        </button>
        <Transition.Root show={openBottom} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={setOpenBottom}>
            <div className="fixed inset-0" />

            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-y-full"
                    enterTo="translate-y-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-y-0"
                    leaveTo="translate-y-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-100">
                      <div className="flex h-full flex-col overflow-y-scroll bg-good-dogs-blue py-6 shadow-xl">
                        <div className="px-4 sm:px-6">
                          <div className="flex items-start justify-between">
                            <Dialog.Title className="text-lg font-medium text-gray-900">The Good Dogs Team</Dialog.Title>
                            <div className="ml-3 flex h-7 items-center">
                              <button
                                type="button"
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                onClick={() => setOpenBottom(false)}
                              >
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="m-0 relative mt-6 flex-1 px-1 sm:px-2">
                          {/* Replace with your content */}
                          <CardContainer />
                          {/* /End replace */}
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </div>
  );
}

const FlipCard = ({ front, back }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleClick = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
      <div className="bg-gray-300 p-8 m-4 text-center h-[23.33rem] sm:w-[20rem] md:h-[18.25rem] md:w-[16.75rem]" onClick={handleClick}>
        { front }
      </div>
      <div className="bg-blue-300 p-8 m-4 text-center h-[23.33rem] sm:w-[20rem] md:h-[18.25rem] md:w-[16.75rem]" onClick={handleClick}>
        { back }
      </div>
    </ReactCardFlip>
  );
};

function CardContainer() {
  return (
    <div className="flex flex-col md:flex-col lg:flex-row content-center w-9/12">
      <FlipCard front={<PapayaFront />} back={<PapayaBack />} />
      <FlipCard front={<TeyaFront />} back={<TeyaBack />} />
      <FlipCard front={<LiamFront />} back={<LiamBack />} />
    </div>
  );
}

function PapayaFront() {
  return(
    <div>
      Nervous Papaya
      <img src={papaya} alt="Nervous Papaya" className="h-full w-full object-cover" />
    </div>
  )
}

function PapayaBack() {
  return(
    <div>
      Artist Extraordinaire
      <div />
      Twitter - @NervousPapaya
      <div />
      Instagram - @NervousPapaya
    </div>
  )
}

function TeyaFront() {
  return(
    <div>
      Teya
      <img src={teya} alt="Teya" className="h-full w-full object-cover" />
    </div>
  )
}

function TeyaBack() {
  return(
    <div className='h-full object-cover w-4/12'>
      Project Mastermind
      <div />
      Twitter - @PopeyeThe13th
    </div>
  )
}

function LiamFront() {
  return(
    <div>
      Liam
      <img src={liam} alt="Liam" className="h-full w-full object-cover" />
    </div>
  )
}

function LiamBack() {
  return(
    <div className='h-full object-cover w-4/12'>
      Developer
      <div />
      Twitter - @oof_liam
    </div>
  )
}

export default App;
